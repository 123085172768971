//import * as React from 'react';
import React from 'react'
import * as ReactDOM from 'react-dom'
import './confirm.css'

interface ConfirmProps {
    text: string
    rejectText?: string
    confirmText?: string
    onConfirm?: Function
    onReject?: Function
}

class Confirm extends React.Component<ConfirmProps, any> {
    public static defaultProps: ConfirmProps = {
        rejectText: 'Cancel',
        confirmText: 'Confirm',
        text: 'Confirm?',
    }

    render() {
        return (
            <div className="confirm-container">
                <div className="confirm">
                    <div className="confirmation-text">{this.props.text}</div>
                    <div className="actions">
                        <button
                            className="pure-button"
                            onClick={this.props.onConfirm as any}>
                            {this.props.confirmText}
                        </button>
                        <button
                            className="pure-button"
                            onClick={this.props.onReject as any}>
                            {this.props.rejectText}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

// http://blog.arkency.com/2015/04/beautiful-confirm-window-with-react/
const confirm = function(options: ConfirmProps) {
    let wrapper = document.body.appendChild(document.createElement('div'))
    ReactDOM.render(React.createElement(Confirm, options), wrapper)

    const cleanup = function() {
        ReactDOM.unmountComponentAtNode(wrapper)
        return setTimeout(function() {
            return wrapper.remove()
        })
    }

    return cleanup
}

export default confirm

import { saveShortcuts } from 'actions/shortcuts'
import { doesUserOwnRewriteEntities } from 'common/rewriteEntity'
import RewriteRuleContext from 'config/rewriteRuleContext'
import UserContext from 'config/userContext'
import { every, isEmpty } from 'lodash'
import { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router'
import Edit from './components/Edit'

const useUpdateShortcuts = () => {
    const queryClient = useQueryClient()
    const history = useHistory()

    return useMutation(saveShortcuts, {
        onSuccess: () => {
            queryClient.invalidateQueries('rewriteRules')
            history.push('/shortcuts')
        },
    })
}

export default () => {
    const updateShortcuts = useUpdateShortcuts()
    const { shortcut: shortcutPath } = useParams<{ shortcut: string }>()

    let { shortcuts: allShortcuts, rewriteEntities } =
        useContext(RewriteRuleContext)

    const user = useContext(UserContext)

    const shortcuts = allShortcuts.filter((x) => x.from_path === shortcutPath)

    const entityIds = shortcuts.map((s) => s.rewrite_entity_id)

    const ownsAllEntities = doesUserOwnRewriteEntities(
        user.managedRewriteEntities,
        entityIds
    )

    const isNew =
        isEmpty(shortcuts) || every(shortcuts, (x) => x.id === undefined)

    return (
        <Edit
            shortcutPath={shortcutPath}
            rewriteEntities={rewriteEntities}
            allShortcuts={allShortcuts}
            shortcuts={shortcuts}
            saveShortcuts={(shortcut, shortcuts) => updateShortcuts.mutate({ shortcut, shortcuts })}
            isNew={isNew}
            enabledEntityIds={user.managedRewriteEntities}
            ownsAllEntities={ownsAllEntities}
        />
    )
}

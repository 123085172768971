import { includes } from 'lodash';
import { Shortcut } from 'types/shortcut.interface';


export const getFilteredShortcuts = 
    (filterBy: string, allShortcuts: Shortcut[]) => {
        if(!filterBy || filterBy.length === 0) {
            return allShortcuts;
        }

         return allShortcuts.filter((rr) => {
            const parser = document.createElement('a');
            parser.href = rr.to_path;

            return includes(rr.from_path.toLowerCase(), filterBy.toLowerCase())
            ||
            includes(parser.pathname.toLowerCase(), filterBy.toLowerCase())
        })
  }
const flags = {
    ru: 'data:image/gif;base64,R0lGODlhEAALANUAAI2N+PLy/fQtLRgY8889aJyc/vz8/McnVvMWFjAw91VV/ePk7/pbW/xkZCor9oOD9yIi9fr6+vf39/hQUPo9PXR0/t7e6vb29pWV/NJHcOwAAPdERPU7O/MAAEpK/cMcTeUAAD8/9e/v+vM0NL4SRfENDQAA5kZG+0BA+jY29zs7+AAA/U9P/OAAAPg0NG9v/ezs9rMAAOjo8n199dxqjPpERHsAAMoyX/gAALGx/lBP/fUhId0AAP7+/vX1/////yH5BAAAAAAALAAAAAAQAAsAAAaGQJ9QGCiKRDCYTLZY+H6/nrRnqBoiEYnE8pxar1nJZevJFc6YNGD9eMwAplVFodB5TihVKuGAQEJxcyx3eXt9EAMqcS+DeHp8fgMDKSYxNBkZBAQ3NwcHHx8kJDc2OA01FBwuAgI7OwgIJSUCLaYMDBO5GxscHCMjrDwdwxrFxSDILS08zEEAOw==',
    il: 'data:image/gif;base64,R0lGODlhEAALANUAAM7OzoKl+52d+cLCwnqe+WqR9o2t/X6i+luF84GU9+fn5+Xl5Zez/tfX17+/v8vLy9PT07u7u/Hx8ePj49XV1cXFxb29veHh4cfHx3CP+oqg+2WL9t/f3+7u+nOY93Wa+P38/XaO93SU+4af9YOZ+oif+mGI9QdH6WuL+enp9oqn+gAl3wAAxZWr+vLy9PT19enp+Pv7+/j4+Nvb2/n5+fr6+vf39/Ly8vb29vPz8/T09PX19fz8/Onp6f39/f7+/iH5BAAAAAAALAAAAAAQAAsAAAaLwJ5QqFgsJhfObNagQE6MaNRgUKkCBwLhw+r9vj+fjxer0WQ2nA3g/Yp5mtI5vcM9FG4QQ0QSbFQ4Ozo7GHhjPB0ZHTAmKYI5LxULhzEtKAIhCCM6OTc6AxNkZWcJCTsunTc5Dhc8NWZogZw3EhI3FisBWFseBb4bJgjCLEtNEAAADxgVAwMOFhERQQA7',
    fr: 'data:image/gif;base64,R0lGODlhEAALANUAAKqqqpqv2fHx8e1kVsTS7Vd7xenp6vSIft7e3viVjNTU1NgAALTF57zL6WaHzOpqXe7u7qG13ebm5uTk5O52arLD5aa53vN+cvf396q94e4JAHKS0uQAAOpXSfFwZMHP68/Pz/BqXMYAAK+/48wAAOxuYvN3a+lmWutdT+hSQ+ZNPvT09Lu7uxdHpfj4+LfI5+xeUOzr60Rsu+Li4p+fn2+P0aa54LOwsK6/5CxXsK7A5Ojo6O3t7evr6+zs7HOV0yH5BAAAAAAALAAAAAAQAAsAAAaLwJ/wt0EgFAoQSKPhOH+E6MeFqa5WicThwIG+GBWer2cwSC4mz2Gx+YB1kN7YnA5dFrVPBZeB8GIGOxIeIQMUCw4NfDYCfjxkhTCHDi8ZFhGNY4IDKB0lJAUvlwEQmhITnSkPoBURAQE+gaczHSkqJyIyI5YRYrISJQ8nA7k5xi0sLDcAADQkJCLRQQA7',
    de: 'data:image/gif;base64,R0lGODlhEAALANUAAPDcbqU/L/x1dfnrl6h9L7W1q6iGL/NHR5mLffWmVvayaZSUh6SkmWloaUpIOnhMS+0pKfblh518fHh4ePpmZvyyqfE8PJ13L/tsbPjlZfXhV0pKR+rTLO+SNu7YOoxlZfLcSPPgevKbRLOKL1hYWOuJJ/lgYLakL2k5OT8/OvZSUu8zM7q6sfdZWb1BMZuEL/zVpMq4r/KAcINbW+bNHeoeHmZJPOiCHe/gba2to5VycoCBgfvxopyckOjPIj8/LyH5BAAAAAAALAAAAAAQAAsAAAaHwI1j6Ej9jsikg8UsFHJQBqPXWyx+y91uMml4SaTNJoX42WISiU73+cwecBSq7KoIBBiKqaU6HCwrEDIBdXh6fH6AEIKEFXl7fX+BEDWDBDAKCgmbIiIdHSUlNwoBJzwZGRoaICAeHhwcPjQ4LycDtwMRuiG8AL4AFyPCIwQEBscvBBfKFxdBADs=',
    ch: 'data:image/gif;base64,R0lGODlhEAALANUAAP97evMAAPr6+v1ycukAAPYpKfcvL/v7+/kAAO0AAPdQUPYqKucAAPf39/sAAPUjI/5cXP7+/vpbW/v8+/38/f5ZWfo/P/UAAPhbW+UAAPpdXflYWPdHR+8AAP11dfxlZflbW/cAAPtJSf5fX/U9PftFRfhXWPpERPEAAOsAAPtgYPlYWfthYvj4+PxmZv97e/ZCQvz8/Pn5+f1tbf39/f56ev0AAPxqavxpaf55efdGRv8AAAAAAAAAAAAAAAAAACH5BAAAAAAALAAAAAAQAAsAAAaAwJ1wuLMZbY4hYvdqNnO1mmeGu1k/O4AWMIJEaDQqFafa5c45SAUcE+NwEhs696XEDgKZbg+yRaM0MXd5LXswGDYDigMxE3gyLQ2GCg5WViIlhAYLBpscCC6hLicWkA0FDwWoMCEsriwaGysmCjowhiQXAbsBKB0JCSkEDATDGUEAOw==',
    uk: 'data:image/gif;base64,R0lGODlhEAALAMQAAP5RTlBlsQAUbZC20/mRjc3V6vg0NZhLaf39+xgrjrrP7b+ZtaGt1vyur+pgVfmFfGGDwYqZz/5jYnubxP/r6jxSrIUbN3GEx9vh9Kxhf+G+32RztcLE3dnd7f7+/////yH5BAAAAAAALAAAAAAQAAsAAAWBYKRMUNllWRcEFXRMTONhDOMRhBdF3IMMEEWj8Yl8JJLPpUH4MBIQRkTz+BwBR0qhckl0PNYwABv+eAoZAnLMNrjdAMu3/HGHEfhtJFCgJO11CwkBAhUMHw8EG3UGCBUIBA4RAgMeDxwXFx5jCCscCA4bEwcBCaYFFhYFAqwJAwkhADs=',
    nl: 'data:image/gif;base64,R0lGODlhEAALANUAAAABdP7+/jRusgAhj/picxZYp/UAAPLy8vr6+vj4+Pb29v56iVqKw/1da0V7ujpytSpmrusAAGSSx/pCUftUZP11gwAAR/96hgAAWe7v7yFgq0J3tw1Rokt/vAAAPFCCv1SFwS9qrtHR0fk8Tf6CkdXV1ftqevcxQvg3Rz52uPxMWvVWavZabv8VMflXaP5ZZ3eezv6XpC5qsvYsPPv7+/pQX/5/j/htfvlIV/T19fxwgPX19fz8/P0AAPT09P8AACH5BAAAAAAALAAAAAAQAAsAAAaFwJ/w1ysWDUhkJPJbLC6Vis5kIhAaLkqNeWk0XloVbjJCnWa4SCtGsjmltyqBtbpFDoE8j0dDIBIJCjs+OyUZenx+gII+Bzkih3t9f4GDBwc+IgMwDCAfHQ4bDwIhEBAaGxYAEg4pozKnGgUFHBwhHqsMnZ+hrgKkELgAwxjFxRbIHsoeQQA7',
    it: 'data:image/gif;base64,R0lGODlhEAALANUAAPdMTACFAOLi4t7e3uYAAPITE/lVVfT09PU9PewAAPIuLli6WQBXAPENDf7+/ly9XPMAAEOvQlS2VQB9AHvKewBlAFS5VPUlJfMZGU+2TwB2APMyMuAAAACJAMLCwvcxMUm0SfQfH/YrK3nIeXTGdOXl5QBvAACRAPQ2Nli8WM3NzfYsLMjIyEuzS/g3NzqqOmjCaPpcXPM1NfZERGC/Ydvb2/X19d0AAP39/fv7+/z8/Pb29vr6+vn5+fj4+Pf39yH5BAAAAAAALAAAAAAQAAsAAAaLwFOnEwiUBILBoAZpJhIBCmU0cuBwOl0uZjAAEh0K7bG4ank912cFIARGZAk2h/Z9RJeZm5SyZM49Pj94IQh7fi10gT87FyEYhhMkGSARdYw2jwUoBBMwlRGLOzYHGAUFGxwaMBERL4KjBwcFDQ0KHCZjCxKCmAcyGwoKNxoVFQwMKiwsHs0cHDfRQQA7',
    at: 'data:image/gif;base64,R0lGODlhEAALANUAAPr6+vj4+OwAAPMAAPo1Nf2iovZycvdLS/b29vpERPQzM/k8Pf1ra/97evT19eYAAP2KivuKivdERP52dvU7O/QcHPIuLvDw8PJlZfJpaf5eXu7u7vhSUvlUVf39/fmAgOLi4vl9ffEPD/thYfctLfVubvxmZvkAAPpdXf7+/uAAAPuFhfxMTP6Pj/UjI/v7+/6Qkf1UVPZ5et/f3/lYWP5ZWf1RUfd2dv1wcfsvL/YpKd0AAPz8/Onp6f0AAP8AACH5BAAAAAAALAAAAAAQAAsAAAaGwJ/w5yv6TsiBUiD4NZ6TCY7BMI1QNM6h2dBoajEbK5FYEBSkLaEAa0Egq09IdjOUMp9HL5Xy8HgvAAABARcXPQ4ge36AgoQIhoiKjIGDAQgOFxuIMzkFERFxc3V3GBgyKicmZWYkOi4VFSKzFqlXNB1aEhIUFAoKFhY7SgNMxg/IKio7zEEAOw==',
    dk: 'data:image/gif;base64,R0lGODlhEAALANUAAPBUVPd6evfr6/d2de8+Ps4AAO9JSff39/VdXeTk5Pvx8exDQ+cuLtUAAPBFRdsAAPr6+vPz8+USEusAAOoiIuYAAOQNDeklJes+PvJhYewxMfRZWe03N+srK/RqavNmZvJdXeEAAPBBQugfH+cZGfn5+fT09OgyMv7+/u5OTsgAAPX19fvn5/NUVO3t7eg1NfFYWOnPz+o5Oe87O/v7+/JPT/EAAPjq6vr5+fzx8f309Pj4+Pz8/MUAAO8AAP39/SH5BAAAAAAALAAAAAAQAAsAAAaTQJtvOPmxJpNKJcR8PHyB6AClG3g+GRAMkGpAEYjNL9dyiGYcTcfQmATCrZ+idk53LovCZLBp1XgKdWoXIxgFPyg/PDw0OCU7BwcrJhErCT+KjBCPkZMRESYJFR4GDgQlNxx3IyQSEicqFR+mMzs3GhSsEhYWDD0VWVs7AikLGDIvJwy+SyEPDS4xDQXUKio9PSpBADs=',
    za: 'data:image/gif;base64,R0lGODlhEAALANUAADExzPUAALGy5crk2KDasvcxMo/IAPa3uAAAjfxlZlO0aamPAGq/dwBnAPlISPmmpujqbkZF0zurXUixaBQUxEGvY+bbWvlXV5ORVlq4d3rIkHJyc+bt5huZODOiSwA5AACFETipWj+rYXO7ZymhTDOmV9nRy9zUVMnBfqKeXnK6XWNfy/i9v0u/d/1vcCicQOnyh4S7WsTD7r62cObjVi6jUPrJygAAtdbp4edpa4eG4W68YF+/f1NTUzukUQAAACH5BAAAAAAALAAAAAAQAAsAAAaIQBCoZSoEjshkwKDREDgPlytBvVgdgQWMl+HhHgmHuEDG/lAQRkYx4NhYh/iBlfttUpaRYlKZ8EUhJQofdj0YFioVEh4eLy8dHT6EGxgnMQoSIYqBNSQThDM0OxUhAzgyAqmqKwsQCiIVBDoAABS2tgAIBlwaAzoRwBG0wwgNDR0CNzcIzM3OQQA7',
    emea: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAYFJREFUeNosUT1LA0EQ3YvHGTEaBSsh+IUpJJjKX2AlCFrZWyQiCJLKIqUIFhaCoETxJwgWdv4DkQQRNERjZSFRwSQGktzOjG/2HO52797Om3lv1lvbKHkmJsJiiDUsMwkTiyWyIuQQSxHI5BuR8emFYDBgcAQoQEtWaWEPBKVZGwLvdlrNzyefhYJ48NNoG8M4M4aIwsxUY2v97fQqVanGiXvgos5QYkKMxJwMrY2GBu1ETnbvHupj20fZSi1xUXwxCNHX8/Q8pjJUKMEDmmRmvneOl1ySeGJyB3PZdEccQyFjYuoGDsVGdnOrz64EuxIWxvLLN1EyAkTfeddsBVlK17P4dgrJJfH57QqOogTjPISwSTbUh/qV15HTwj2H6h5NLov1cjWulQxDMlYQMBUdn7BbyeYPM9l0s7T3uDjf2txPaReUh54BSBIf3bud30Ry1LA6Y2wktY9k4WwSYoeT+CWdoOV+tw2GD/bXezly9W/NSXd3L+7K3ZWqIAbwJ8AAqIB6+GR9QGYAAAAASUVORK5CYII='
}

export default flags
import 'react-app-polyfill/ie11';
import * as React from 'react';
import { render } from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import Home from 'screens/Home';
import Login from 'screens/Login';
import ShortcutEditor from 'screens/Shortcuts/Edit';
import ShortcutList from 'screens/Shortcuts/List';
import './app.scss';
import Header from './common/menu';
import Loading from './common/loading';
import './css/select-css.css';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import axios from 'config/network';
import { Redirect, Switch } from 'react-router';
import jwtDecode from 'jwt-decode';
import RewriteRuleContext from 'config/rewriteRuleContext';
import { Shortcut } from 'types/shortcut.interface';
import UserContext from 'config/userContext';
import { User } from 'common/user.interface';
import RewriteEntity from 'types/rewrite-entity';
import Reserved from 'screens/Shortcuts/reserved';

interface AppProps {
  children: any

}
const queryClient = new QueryClient({defaultOptions: {queries: { refetchOnWindowFocus: false}}})
const App = ({ children }: AppProps) => {
  const { isLoading, data } = useQuery('rewriteRules', () => axios.get<Shortcut[]>('rewrite_rules'))
  const { isLoading: entityLoading, data: entityData } = useQuery('rewriteEntities', () => axios.get<RewriteEntity[]>('rewrite_entities'))
  const [ expandedShortcutGroupKeys, setExpandedShortcutGroupKeys ] = React.useState<string[]>([])
  const [filterTerm, setFilterTerm] = React.useState('')

  if(isLoading || entityLoading) {
    return <Loading />
  }

  const filterShortcuts = (term: string) => {
    setFilterTerm(term)
  }

  const toggleShortcutGroupExpand = (key: string) => {
    let result

    if(expandedShortcutGroupKeys.includes(key)) {
      result = expandedShortcutGroupKeys.filter(x => x !== key)
    } else {
      result = expandedShortcutGroupKeys.concat(key)
    }

    setExpandedShortcutGroupKeys(result)
  }

  const shortcuts = data?.data.map((rr: Shortcut) =>
    Object.assign({}, rr, { enabled: true, isValid: true })
  )

  return (
    <RewriteRuleContext.Provider value={{
      filterShortcuts,
      filterTerm,
      shortcuts,
      rewriteEntities: entityData?.data,
      expandedShortcutGroupKeys,
      toggleShortcutGroupExpand
    }}>


      <div className="root">
        <Header />
        <section className="content">{children}</section>
      </div>
    </RewriteRuleContext.Provider>
  )
}

const PrivateRoute = ({ children, ...rest }) => {
  console.log('Private Route', children, rest)
  const token = window.localStorage.getItem('token')
  const currentTime = Date.now() / 1000

  // check if token is still valid
  const expired = token
    ? jwtDecode<{ exp: number }>(token).exp < currentTime
    : true

  return (
    <Route
      {...rest}
      render={props =>
        !expired ?
          children
         : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

const token = window.localStorage.getItem('token')
const user = token ? jwtDecode<User>(token) : null

render(
  <UserContext.Provider value={user}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
        <Route path="/login"><Login /></Route>

        <PrivateRoute path="/">
          <App>
          <Switch>
          <Route path="/" exact><Redirect to="/home" /></Route>

          <Route path="/home"><Home /></Route>
          <Route path="/shortcuts/reserved" exact><Reserved /></Route>
          <Route path="/shortcuts"><ShortcutList /></Route>

          <Route
            path="/shortcut/:shortcut/edit"
          ><ShortcutEditor /></Route>
          <Route path="/shortcut/new"><ShortcutEditor /></Route>
          </Switch>
          </App>
        </PrivateRoute>
        </Switch>

      </Router></QueryClientProvider></UserContext.Provider>,
  document.querySelector('#root')
)

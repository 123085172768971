import * as React from 'react';
import { Link } from 'react-router-dom';

const Home = () => (
    <section>
        <ul>
            <li><Link to="/shortcuts">Manage Shortcuts</Link></li>
        </ul>
    </section>
)

export default Home
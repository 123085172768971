import classNames from 'classnames'
import { removeKnownHosts } from 'helpers/url'
import { every, find, indexOf, isEqual, remove } from 'lodash'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { blacklist, offsite } from 'screens/Shortcuts/blacklist'
import RewriteEntity from 'types/rewrite-entity'
import { Shortcut } from 'types/shortcut.interface'
import Conflicts from '../Conflicts'
import Row from '../Row'

interface ShortcutEditorProps {
    isNew: boolean
    enabledEntityIds: number[]
    ownsAllEntities: boolean
    shortcuts: Shortcut[]
    allShortcuts: Shortcut[]
    rewriteEntities: RewriteEntity[]
    shortcutPath: string
    saveShortcuts(shortcut: string, shortcuts: Array<Shortcut>): any
}

interface ShortcutEditorState {
    shortcutPath: string
    proposedShortcuts?: Shortcut[]
    showHelp?: boolean
    isBlacklisted: boolean
    isOffsite: boolean
}

const isConflicting = (
    proposedShortcutPath: string,
    originalShortcutPath: string,
    shortcuts: Shortcut[]
) => {
    if (blacklist.indexOf(proposedShortcutPath.toLowerCase()) > -1) {
        return false
    }

    if (proposedShortcutPath === originalShortcutPath) {
        return false
    }

    const match = find(shortcuts, existingShortcut => {
        return (
            existingShortcut.from_path.toLowerCase() ===
            proposedShortcutPath.replace('/', '').toLowerCase()
        )
    })

    return match !== undefined
}

class ShortcutEditor extends React.Component<
    ShortcutEditorProps,
    ShortcutEditorState
> {
    refs: {
        [string: string]: any
        shortcutPath: HTMLInputElement
    }

    constructor(props) {
        super(props)
        this.state = {
            isOffsite: false,
            isBlacklisted: false,
            showHelp: false,
            shortcutPath: props.shortcutPath || '',
            proposedShortcuts: props.shortcuts || [],
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return isEqual(nextProps.shortcuts, this.props.shortcuts)
    }

    componentWillReceiveProps(props) {
        this.setState({
            isOffsite: false,
            isBlacklisted: false,
            shortcutPath: props.shortcutPath || '',
            proposedShortcuts: props.shortcuts || [],
        })
    }

    onShortcutPathChange() {
        const fromPath = this.refs.shortcutPath.value.toLowerCase()

        this.setState({
            shortcutPath: fromPath,
            isBlacklisted: blacklist.indexOf(fromPath) > -1,
            isOffsite: offsite.indexOf(fromPath) > -1,
        })

        this.props.rewriteEntities.forEach((entity: RewriteEntity, idx) => {
            this.refs[this.buildRowRef(idx)].setFromPath(fromPath)
        })
    }

    onShortcutType = e => {
        if (!e.key.match(/[a-z0-9-_]/gi)) {
            e.preventDefault()
            return false
        }

        return true
    }

    onDestinationType = e => {
        const valid =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~:/?#[]@!$&'()*+,;=`."
        if (!valid.includes(e.key)) {
            e.preventDefault()
            return false
        }

        return true
    }

    buildRowRef(idx) {
        return 'edit-row-idx-' + idx
    }

    renderRows() {
        const { enabledEntityIds, rewriteEntities } = this.props

        return rewriteEntities.map((c, idx) => {
            const shortcut = find(this.state.proposedShortcuts, sc => {
                return sc.rewrite_entity_id === c.id
            })

            return (
                <Row
                    allRewriteEntities={this.props.rewriteEntities}
                    disabled={enabledEntityIds.indexOf(c.id) === -1}
                    onDisabled={this.onShortcutDisabled}
                    onUpdated={this.onShortcutUpdated}
                    onType={this.onDestinationType}
                    key={c.id}
                    rewriteEntity={c}
                    ref={this.buildRowRef(idx)}
                    fromPath={this.state.shortcutPath}
                    shortcut={shortcut}
                />
            )
        })
    }

    onShortcutDisabled = (shortcut: Shortcut) => {
        const proposedShortcuts = this.state.proposedShortcuts

        remove(proposedShortcuts, sc => {
            return (
                sc.id === shortcut.id &&
                sc.rewrite_entity_id === shortcut.rewrite_entity_id
            )
        })

        this.setState({ proposedShortcuts } as any)
    }

    onShortcutUpdated = (shortcut: Shortcut) => {
        if (!shortcut) {
            return false
        }

        const idx = indexOf(
            this.state.proposedShortcuts,
            find(this.state.proposedShortcuts, sc => {
                return (
                    sc.id === shortcut.id &&
                    sc.rewrite_entity_id === shortcut.rewrite_entity_id
                )
            })
        )

        let newState = this.state.proposedShortcuts

        if (idx > -1) {
            newState[idx] = shortcut
        } else {
            newState.push(shortcut)
        }

        this.setState({ proposedShortcuts: newState } as any)
    }

    onSubmit = () => {
        this.props
            .saveShortcuts(
                this.state.shortcutPath,
                this.state.proposedShortcuts.filter(rr => rr.enabled)
            )
    }

    toggleAll = e => {
        const { rewriteEntities, enabledEntityIds } = this.props

        rewriteEntities.forEach((entity, idx) => {
            if (enabledEntityIds.indexOf(entity.id) > -1) {
                this.refs[this.buildRowRef(idx)].setEnabled(e.target.checked)
            }
        })
    }

    autoFillAll = e => {
        const { rewriteEntities, enabledEntityIds } = this.props

        rewriteEntities.forEach((entity, idx) => {
            if (enabledEntityIds.indexOf(entity.id) > -1) {
                this.refs[this.buildRowRef(idx)].setValue(removeKnownHosts(e.target.value))
            }
        })
    }

    getTitle = () => {
        const { ownsAllEntities, isNew } = this.props

        const { shortcutPath } = this.state

        let title = isNew ? 'Create New Shortcut:' : 'Edit Existing Shortcut'

        if (!ownsAllEntities && !isNew) {
            title = `Edit Shortcut "${shortcutPath}"`
        }

        return title
    }

    isValid = () => {
        const childrenValid = every(this.state.proposedShortcuts, 'isValid')
        const count = this.state.proposedShortcuts.filter(v => v.enabled).length

        return childrenValid && count > 0 && this.state.shortcutPath.length > 0
    }

    onHelpToggle = e => {
        e.preventDefault()
        this.setState({
            isOffsite: this.state.isOffsite,
            isBlacklisted: this.state.isBlacklisted,
            showHelp: !this.state.showHelp,
            shortcutPath: this.state.shortcutPath,
        })
    }

    render() {
        const { ownsAllEntities, isNew } = this.props

        const { showHelp, isBlacklisted, isOffsite, shortcutPath } = this.state

        const isInConflict = isConflicting(
            this.state.shortcutPath,
            this.props.shortcutPath,
            this.props.allShortcuts
        )

        return (
            <section className="shortcut-editor">
                <h1>
                    {this.getTitle()}
                    {ownsAllEntities ? (
                        <input
                            ref="shortcutPath"
                            placeholder="Shortcut Path"
                            className={classNames('edit-from-path', {
                                blacklisted: isBlacklisted || isOffsite,
                            })}
                            onKeyPress={this.onShortcutType}
                            onChange={this.onShortcutPathChange.bind(this)}
                            value={this.state.shortcutPath}
                        />
                    ) : null}
                    <a
                        href="#"
                        className={(showHelp ? 'help-selected ' : '') + 'help'}
                        onClick={this.onHelpToggle}>
                        ?
                    </a>
                </h1>

                {isBlacklisted ? (
                    <p className="blacklist-warning">The term "{shortcutPath}" is <Link to="/shortcuts/reserved">blacklisted for internal use</Link>, and cannot be used as a shortcut.</p>
                ) : null}

                {isOffsite ? (
                    <p className="blacklist-warning">The term "{shortcutPath}" is already in use as one of the <Link to="/shortcuts/reserved">off-site shortcuts</Link> and cannot be reused.</p>
                ) : null}

                {isInConflict ? (
                    <Conflicts proposedShortcutPath={this.state.shortcutPath} />
                ) : null}

                {showHelp ? (
                    isNew ? (
                        <div className="help-container">
                            <p>Step 1: Shortcut Path</p>

                            <p>
                                Example: 'alpha' is the path in this example:
                                https://uk.tdk-lambda.com/alpha (The system will
                                check if the path has been used, duplicates are
                                not allowed.)
                            </p>

                            <p>
                                Using tick boxes, select the country sites that
                                the shortcut is for. (If the shortcut is for all
                                country sites, use the 'toggle all' option.)
                            </p>

                            <p>Step 2: Destination</p>

                            <p>
                                This is the destination web page, for example
                                https://uk.tdk-lambda.com/products/product-details.aspx?scid=279
                            </p>
                            <p>
                                Use the 'auto-fill destination' option to use
                                the same page in all the selected country sites.
                            </p>

                            <p>
                                Note: only the part of the URL after the first
                                forward slash is required, for example
                                'products/product-details.aspx?scid=279'.
                            </p>
                            <p>
                                If an EMEA shortcut is required, select the
                                country site it should route to.
                            </p>
                        </div>
                    ) : (
                        <div className="help-container">
                            <p>
                                You can edit the shortcut path or the shortcut's
                                destination(s).
                            </p>
                            <p>
                                Editing the path will affect the shortcut for
                                all countries.The destination can be edited on a
                                per-country basis.
                            </p>

                            <p>
                                Note: if you edit the shortcut path, published
                                references to the old path will no longer work.
                            </p>

                            <p>
                                To remove a shortcut from a specific country,
                                simply untick the country.
                            </p>

                            <p>
                                Note: Shortcuts can only be deleted from all
                                countries via the shortcut admin 'home' page.
                                The delete option is located under each
                                shortcut.
                            </p>
                        </div>
                    )
                ) : null}

                <table>
                    <tbody>
                        <tr>
                            <td colSpan={5}>
                                <div>
                                    <input
                                        type="checkbox"
                                        onClick={this.toggleAll}
                                    />{' '}
                                    Toggle All/None
                                </div>
                            </td>
                            <td>
                                <input
                                    onKeyPress={this.onDestinationType}
                                    type="text"
                                    placeholder="Auto-Fill Destination"
                                    className="auto-fill-destination"
                                    size={60}
                                    onChange={this.autoFillAll}
                                />
                            </td>
                        </tr>
                        {this.renderRows()}
                    </tbody>
                </table>

                <Link to="/shortcuts" className="pure-button">
                    Back to list
                </Link>
                <button
                    onClick={this.onSubmit}
                    disabled={!this.isValid() || isInConflict || isBlacklisted}
                    className="pure-button">
                    Save changes
                </button>
            </section>
        )
    }
}

export default ShortcutEditor

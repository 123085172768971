import axios from 'config/network'
import { Shortcut } from 'types/shortcut.interface'

export const deleteShortcut = (fromPath: string) => axios.delete('/rewrite_rules/' + fromPath).then((res) => res.data)

export const saveShortcuts = (
{shortcut, shortcuts}: {    shortcut: string,
    shortcuts: Shortcut[]}
) => axios.put(
    '/rewrite_rules/' + shortcut,
    { rewrite_rules: shortcuts }
).then((res) => res.data)

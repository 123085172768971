import * as React from 'react'

const DeleteButton = ({onClick, canDelete}) => (
    <button 
        onClick={onClick}
        disabled={!canDelete}
        className="pure-button">
        Delete
    </button>    
)

export default DeleteButton
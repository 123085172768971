import { every } from 'lodash'

// when a user can manage all of the entities for shortcuts in this group
// - they can delete them en-masse
// - they can amend the shortcut when editing
const doesUserOwnRewriteEntities = (
    userRewriteEntityIds: number[],
    rewriteEntityIds: number[]
): boolean => {
    return every(rewriteEntityIds, x => userRewriteEntityIds.indexOf(x) > -1)
}

export { doesUserOwnRewriteEntities }

import { deleteShortcut } from 'actions/shortcuts';
import RewriteRuleContext from 'config/rewriteRuleContext';
import UserContext from 'config/userContext';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { mapGroups } from '../grouping.selector';
import ShortcutList from './components/List';
import { getFilteredShortcuts } from './filter.selector';
import './index.scss';

const ListContainer = () => {
    const queryClient = useQueryClient()
    const { shortcuts, rewriteEntities, toggleShortcutGroupExpand, expandedShortcutGroupKeys, filterShortcuts, filterTerm } = useContext(RewriteRuleContext)
    const user = useContext(UserContext)
    const shortcutGroups = mapGroups(getFilteredShortcuts(filterTerm, shortcuts), expandedShortcutGroupKeys, user);

    const mutation = useMutation(deleteShortcut, {
        onSuccess: () => queryClient.invalidateQueries('rewriteRules')
    })

    return <ShortcutList
        toggleShortcutGroupExpand={toggleShortcutGroupExpand}
        shortcutGroups={shortcutGroups}
        currentFilter={filterTerm}
        rewriteEntities={rewriteEntities}
        filterShortcuts={filterShortcuts}
        deleteShortcut={(fromPath) => {
            mutation.mutate(fromPath)
        }}
    />
}

export default ListContainer
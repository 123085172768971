import { createContext } from "react";
import RewriteEntity from "types/rewrite-entity";
import { Shortcut } from "types/shortcut.interface";

const RewriteRuleContext = createContext<{
    shortcuts: Shortcut[],
    rewriteEntities: RewriteEntity[],
    expandedShortcutGroupKeys: string[]
    toggleShortcutGroupExpand: (key: string) => void
    filterShortcuts: (term: string) => void
    filterTerm: string
}>(null);

export default RewriteRuleContext
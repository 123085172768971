import flagMap from 'common/flagMap'
import { find } from 'lodash'
import * as React from 'react'
import Collapsible from 'react-collapsible'
import { Link } from 'react-router-dom'
import RewriteEntity from 'types/rewrite-entity'
import { Shortcut } from 'types/shortcut.interface'
import DeleteButton from '../DeleteButton'

interface ShortcutGroupProps {
    canDelete: Boolean
    shortcuts: Shortcut[]
    rewriteEntities: RewriteEntity[]
    onToggleCollapse: Function
    index: number
    expanded: Boolean
    onDeleteShortcut(shortcut: string): any
}

class ShortcutGroup extends React.Component<ShortcutGroupProps> {
    private collapsible

    constructor(props) {
        super(props)
        this.collapsible = React.createRef()
    }

    renderRewriteEntities() {
        return this.props.shortcuts.map(shortcut => {
            let c = find(
                this.props.rewriteEntities,
                (entity: RewriteEntity) =>
                    shortcut.rewrite_entity_id === entity.id
            )

            if (!c) {
                console.error(
                    'Error when finding rewrite entity for shortcut',
                    shortcut,
                    this.props.shortcuts
                )
                return (
                    <tr key={'tr-' + shortcut.id}>
                        <td colSpan={4}>
                            Unknown Rewrite Entity ID:{' '}
                            {shortcut.rewrite_entity_id}
                        </td>
                    </tr>
                )
            }

            return (
                <tr key={'tr-' + shortcut.id}>
                    <td className="rewrite-entity-name">
                        <img src={flagMap[c.code]} /> {c.name}
                    </td>
                    <td className="from">
                        <a
                            target="_blank"
                            href={
                                'https://' +
                                shortcut.from_host +
                                '/' +
                                shortcut.from_path
                            }>
                            {shortcut.from_host + '/' + shortcut.from_path}
                        </a>
                    </td>
                    <td className="to">
                        <i
                            className="fa fa-long-arrow-right"
                            aria-hidden="true"
                        />{' '}
                        <a
                            target="_blank"
                            href={
                                'https://' +
                                shortcut.to_host +
                                '/' +
                                shortcut.to_path
                            }>
                            {shortcut.to_host + '/' + shortcut.to_path}
                        </a>
                    </td>
                </tr>
            )
        })
    }

    renderRows() {
        const { canDelete, index, onDeleteShortcut, shortcuts } = this.props

        const fromPath = shortcuts[0].from_path
        const encodedFromPath = encodeURIComponent(fromPath)

        return (
            <tbody key={'tbody-' + index}>
                {this.renderRewriteEntities()}
                <tr className="actions">
                    <td>
                        <Link
                            className="pure-button"
                            to={`shortcut/${encodedFromPath}/edit`}>
                            Edit
                        </Link>
                        <DeleteButton
                            canDelete={canDelete}
                            onClick={() => onDeleteShortcut(fromPath)}
                        />
                    </td>
                </tr>
            </tbody>
        )
    }

    handleTriggerClick = () => {
        if (this.collapsible.current.state.isClosed) {
            this.collapsible.current.openCollapsible()
        } else {
            this.collapsible.current.closeCollapsible()
        }

        this.props.onToggleCollapse(this.props.shortcuts[0].from_path)
    }

    render() {
        return (
            <div className="shortcut-group">
                <Collapsible
                    open={this.props.expanded}
                    transitionTime={1}
                    ref={this.collapsible}
                    handleTriggerClick={this.handleTriggerClick}
                    trigger={this.props.shortcuts[0].from_path}>
                    <table style={{borderCollapse: 'collapse'}}>{this.renderRows()}</table>
                </Collapsible>
            </div>
        )
    }
}

export default ShortcutGroup

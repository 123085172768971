import { debounce } from 'lodash'
import * as React from 'react'

interface FilterProps {
    onFilterChange: (value: string) => void
    filterValue: string
}

interface FilterState {
    value: string
}

class Filter extends React.Component<FilterProps, FilterState> {
    private onFilterChange

    constructor(props) {
        super(props)

        this.state = {
            value: props.filterValue,
        }

        this.onFilterChange = debounce(this.props.onFilterChange, 1000)
    }

    componentWillUnmount() {
        this.onFilterChange.cancel()
    }

    onChange = e => {
        e.persist()
        const val = e.target.value
        this.setState({ value: val }, () => {
            this.onFilterChange(val)
        })
    }

    render() {
        return (
            <div className="filter">
                <input
                    type="search"
                    placeholder="Filter..."
                    value={this.state.value}
                    onChange={this.onChange}
                />
            </div>
        )
    }
}

export default Filter

import axios from 'config/network'
import React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import './login.css'

const Login = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const history = useHistory()

    const onSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        try{
            const response = await axios.post('/user_token', { auth: { email: email, password: password } })
            localStorage.setItem('token', response.data.jwt)
            console.log('pushing')
            window.location.href = '/home'
        } catch (e) {
            console.error(e)
            setIsLoading(false)
        }
    }

    return (
        <div className="login-container">
            <form className="login" onSubmit={onSubmit}>
                <img src={require('../../img/logo.png').default} alt="TDK-Lambda" />
                <input placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <input
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button
                    className="btn"
                    type="submit">
                    {isLoading
                        ? 'Submitting...'
                        : 'Submit Login'}
                </button>
            </form>
        </div>
    )
}

export default Login
import confirm from 'common/confirm'
import { ShortcutGroup } from 'common/shortcutGroup.interface'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { AutoSizer, List } from 'react-virtualized'
import RewriteEntity from 'types/rewrite-entity'
import Filter from '../Filter'
import Group from '../Row'

interface ShortcutListProps {
    toggleShortcutGroupExpand: Function
    shortcutGroups: ShortcutGroup[]
    rewriteEntities: RewriteEntity[]
    filterShortcuts: (sc: string) => void
    deleteShortcut: (sc: string) => any
    currentFilter: string
}

interface ShortcutListState {
    showHelp: boolean
}

export class ShortcutList extends React.Component<
    ShortcutListProps,
    ShortcutListState
> {
    private list

    constructor(props) {
        super(props)

        this.list = React.createRef()
        this.state = {
            showHelp: false,
        }
    }

    componentDidMount() {
        this.list.current.recomputeRowHeights()
        this.list.current.forceUpdate()
    }

    onDeleteShortcut = shortcut => {
        const close = confirm({
            text: `Are you sure you'd like to delete the shortcut "${shortcut}"?`,
            confirmText: 'Yes',
            rejectText: 'No',
            onReject: () => {
                close()
            },
            onConfirm: () => {
                close()
                this.props.deleteShortcut(shortcut)
            },
        })
    }

    onHelpToggle = e => {
        e.preventDefault()
        this.setState({
            showHelp: !this.state.showHelp,
        })
    }

    renderGroup({ index, style }) {
        const group = this.props.shortcutGroups[index]
        const shortcuts = group.shortcuts

        return (
            <div key={`group-container-${index}`} style={style}>
                <Group
                    canDelete={group.canDelete}
                    expanded={group.isExpanded}
                    onToggleCollapse={this.recomputeSizes.bind(this)}
                    onDeleteShortcut={this.onDeleteShortcut}
                    rewriteEntities={this.props.rewriteEntities}
                    key={index}
                    index={index}
                    shortcuts={shortcuts}
                />
            </div>
        )
    }

    recomputeSizes(index) {
        this.props.toggleShortcutGroupExpand(index)
        this.list.current.recomputeRowHeights()
        this.list.current.forceUpdate()
    }

    calculateRowHeight({ index }): number {
        const shortcutHeight = 28
        const padding = 10 * 4 // top and bottom padding of container + top and bottom padding of list
        const expander = 23
        const buttons = 45
        const extraHeight =  padding + expander + buttons

        // If this row is expanded
        if (this.props.shortcutGroups[index].isExpanded) {
            const shortcutCount: number = this.props.shortcutGroups[index]
                .shortcuts.length
            return shortcutCount * shortcutHeight + extraHeight
        } else {
            // Return the height of an unexpanded row
            return 49
        }
    }

    render() {
        const { showHelp } = this.state

        return (
            <section className="shortcut-manager">
                <h1>
                    Shortcut Management
                    <Filter
                        filterValue={this.props.currentFilter}
                        onFilterChange={val => {
                            this.props.filterShortcuts(val)
                        }}
                    />
                    <a
                        href="#"
                        className={(showHelp ? 'help-selected ' : '') + 'help'}
                        onClick={this.onHelpToggle}>
                        ?
                    </a>
                    <Link className="add-new" to="shortcut/new">
                        Add New
                    </Link>
                </h1>

                <p>Found {this.props.shortcutGroups.length} shortcuts.</p>

                {showHelp ? (
                    <div className="help-container">
                        <p>
                            The shortcut admin tools allows you to create, edit
                            and delete shortcuts to any page on the TDK-Lambda
                            web site(s).
                        </p>
                        <p>Shortcut example: https://uk.tdk-lambda.com/alpha</p>
                        <p>
                            This page doesn't exist on the website, instead the
                            link automatically goes to:
                            https://uk.tdk-lambda.com/products/product-details.aspx?scid=279
                        </p>
                        <p>
                            From here you can select a shortcut from the list
                            below, search for a shortcut or add a new shortcut.
                        </p>
                    </div>
                ) : null}

                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flex: '1 1 auto', height: '100%' }}>
                        <AutoSizer>
                            {({ width, height }) => (
                                <List
                                    style={{ outline: 'none' }}
                                    overscanRowCount={25}
                                    ref={this.list}
                                    height={height}
                                    rowCount={this.props.shortcutGroups.length}
                                    rowHeight={this.calculateRowHeight.bind(
                                        this
                                    )}
                                    rowRenderer={({ index, style }) =>
                                        this.renderGroup({ index, style })
                                    }
                                    width={width}
                                />
                            )}
                        </AutoSizer>
                    </div>
                </div>
            </section>
        )
    }
}

export default ShortcutList

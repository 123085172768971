import classNames from 'classnames'
import { removeKnownHosts } from 'helpers/url'
import { findIndex } from 'lodash'
import * as React from 'react'
import RewriteEntity from 'types/rewrite-entity'
import { Shortcut } from 'types/shortcut.interface'

interface RowProps {
    disabled: boolean
    shortcut?: Shortcut
    fromPath?: string
    rewriteEntity?: RewriteEntity
    allRewriteEntities: RewriteEntity[]
    onUpdated: (shortcut: Shortcut) => void | null
    onDisabled: (shortcut: Shortcut) => void | null
    onType: (e: React.FormEvent<HTMLElement>) => void | null
}

export default class Row extends React.Component<RowProps, null> {
    refs: {
        enabled: HTMLInputElement
    }

    public static defaultProps: RowProps = {
        disabled: false,
        onUpdated: null,
        onDisabled: null,
        onType: null,
        allRewriteEntities: [],
        shortcut: {
            id: 0,
            enabled: false,
            rewrite_entity_id: null,
            from_host: null,
            from_path: null,
            to_path: '',
            to_host: null,
            isValid: true,
        },
    }

    onToPathChanged = (toPath: string) => {
        this.props.onUpdated(
            Object.assign({}, this.getShortcut(), {
                to_path: toPath,
                isValid: this.isValid(this.props.shortcut.enabled, toPath),
            })
        )
    }

    onToHostChange = event => {
        this.props.onUpdated(
            Object.assign({}, this.getShortcut(), {
                to_host: this.props.allRewriteEntities[event.target.value]
                    .domain,
            })
        )
    }

    setEnabled(enabled) {
        this.props.onUpdated(
            Object.assign({}, this.getShortcut(), {
                enabled,
                isValid: this.isValid(enabled, this.props.shortcut.to_path),
            })
        )
    }

    setValue(toPath) {
        if (this.props.shortcut) {
            this.onToPathChanged(toPath)
        }
    }

    setFromPath(fromPath) {
        this.props.onUpdated(
            Object.assign({}, this.getShortcut(), {
                from_path: fromPath,
            })
        )
    }

    getShortcut(): Shortcut {
        return Object.assign({}, this.props.shortcut, {
            rewrite_entity_id: this.props.rewriteEntity.id,
            from_host: this.props.rewriteEntity.domain,
            from_path: this.props.fromPath,
            to_host:
                this.props.shortcut.to_host || this.props.rewriteEntity.domain,
        })
    }

    isValid(enabled, toPath) {
        // If the shortcut is not present then skip validation
        if (!enabled) {
            return true
        }

        return toPath && toPath.length > 0
    }

    renderToHostPicker() {
        const {
            rewriteEntity,
            allRewriteEntities,
            shortcut,
            disabled,
        } = this.props

        if (rewriteEntity && rewriteEntity.allowToHostEditing) {
            const selectedIdx: Number = findIndex(
                allRewriteEntities,
                (entity: RewriteEntity) => entity.domain === shortcut.to_host
            )

            return (
                <div
                    className={`custom-select ${
                        this.props.shortcut.enabled ? '' : 'select-disabled'
                    }`}>
                    <select
                        value={selectedIdx.toString()}
                        disabled={!this.props.shortcut.enabled || disabled}
                        onChange={this.onToHostChange}>
                        <option value="-1">Choose Target Host...</option>
                        {allRewriteEntities.map((entity, idx) => (
                            <option key={entity.id} value={idx.toString()}>
                                {entity.name}
                            </option>
                        ))}
                    </select>
                </div>
            )
        }

        return null
    }

    render() {
        const { onType, disabled } = this.props

        const entity = this.props.rewriteEntity

        const toInputClass = classNames({
            'is-invalid': !this.isValid(
                this.props.shortcut.enabled,
                this.props.shortcut.to_path
            ),
        })

        return (
            <tr>
                <td className="shortcut-enabled">
                    <label>
                        <input
                            disabled={disabled}
                            onChange={e =>
                                this.setEnabled(e.currentTarget.checked)
                            }
                            type="checkbox"
                            ref="enabled"
                            checked={this.props.shortcut.enabled}
                        />{' '}
                        {entity.name}
                    </label>
                </td>
                <td>{this.renderToHostPicker()}</td>
                <td className="shortcut-path">
                    {entity.domain}/{this.props.fromPath}
                </td>
                <td>
                    <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"></i>
                </td>
                <td className="shortcut-to-host">
                    {this.props.shortcut.to_host || entity.domain}/
                </td>
                <td className="shortcut-to">
                    <input
                        onKeyPress={onType}
                        size={60}
                        disabled={!this.props.shortcut.enabled || disabled}
                        ref="to"
                        className={toInputClass}
                        value={
                            this.props.shortcut.enabled
                                ? this.props.shortcut.to_path
                                : ''
                        }
                        onChange={e =>
                            this.onToPathChanged(removeKnownHosts((e.currentTarget.value)))
                        }
                    />
                </td>
            </tr>
        )
    }
}

import axiosBase from "axios";
import { API_ENDPOINT } from "constants/config";

const axios = axiosBase.create({
  baseURL: API_ENDPOINT
});
axios.interceptors.request.use(
  (config: any) => {
    console.log('sending token', window.localStorage.getItem('token'))
    config.headers.Authorization = `Bearer: ${window.localStorage.getItem('token')}`
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

export default axios
import * as React from 'react';
import { Link } from 'react-router-dom';

interface ConflictsParams {
    proposedShortcutPath: string,
}

class Conflicts extends React.Component<ConflictsParams> {
    render() {
        const {
            proposedShortcutPath,
        } = this.props

        return <div className="conflicts-container">
            <p>
                The term '{proposedShortcutPath}' is already being used as a Short URL. <a  href={`/shortcut/${encodeURIComponent(proposedShortcutPath)}/edit`}>Edit the existing Shortcuts</a> or pick another term.
            </p>
        </div>
    }
}

export default Conflicts;
import React from 'react'
import { blacklist } from './blacklist'

interface Props {}

const Reserved = (props: Props) => {
    return (
        <section className="reserved-shortcuts">
                <h1>Reserved Shortcuts</h1>

<p>These are shortcuts which refer to off-site destinations:</p>

        <table className="offsite">
            <thead>
            <tr>
                <td>Rule</td>
                <td>Owner</td>
                <td>Short Url</td>
                <td>Destination</td>
            </tr>
            </thead>
            <tbody>


            <tr>
                <td>twitter</td>
                <td>UK</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/uk/twitter</a>
                </td>
                <td>
                    <a></a>
                    <a>https://twitter.com/tdklambda_uk</a>
                </td>
            </tr>
            <tr>
                <td>twitter</td>
                <td>Italy</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/it/twitter</a>
                </td>
                <td>
                    <a></a>
                    <a>https://twitter.com/TDKLambda_IT</a>
                </td>
            </tr>
            <tr>
                <td>twitter</td>
                <td>Germany</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/de/twitter</a>
                </td>
                <td>
                    <a></a>
                    <a>https://twitter.com/TDKLambda_DE</a>
                </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>facebook</td>
                <td>UK</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/uk/facebook</a>
                </td>
                <td>
                    <a></a>
                    <a>https://facebook.com/TDK-Lambda-UK-150953654965106/</a>
                </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>youtube</td>
                <td>UK</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/uk/youtube</a>
                </td>
                <td>
                    <a></a>
                    <a>https://youtube.com/user/TDKLambdaUK</a>
                </td>
            </tr>
            <tr>
                <td>youtube</td>
                <td>Germany</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/de/youtube</a>
                </td>
                <td>
                    <a></a>
                    <a>https://youtube.com/user/TDKLambdaUK</a>
                </td>
            </tr>
            <tr>
                <td>youtube</td>
                <td>EMEA</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/youtube</a>
                </td>
                <td>
                    <a></a>
                    <a>https://youtube.com/user/TDKLambdaUK</a>
                </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>linkedin</td>
                <td>UK</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/uk/linkedin</a>
                </td>
                <td>
                    <a></a>
                    <a>
                        https://linkedin.com/company/tdk-lambda-uk?trk=top_nav_home
                    </a>
                </td>
            </tr>
            <tr>
                <td>linkedin</td>
                <td>Germany</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/de/linkedin</a>
                </td>
                <td>
                    <a></a>
                    <a>
                        https://linkedin.com/company/tdk-lambda-germany-gmbh?trk=biz-companies-cym
                    </a>
                </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>googleplus</td>
                <td>UK</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/uk/googleplus</a>
                </td>
                <td>
                    <a></a>
                    <a>
                        https://plus.google.com/101496218100174597067#101496218100174597067/posts
                    </a>
                </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>blog</td>
                <td>UK</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/uk/blog</a>
                </td>
                <td>
                    <a></a>
                    <a>https://blog.uk.tdk-lambda.com/uk</a>
                </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>Zplus_blog</td>
                <td>UK</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/uk/Zplus_blog</a>
                </td>
                <td>
                    <a></a>
                    <a>https://blog.uk.tdk-lambda.com/uk/2012/07/30/751/</a>
                </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>wizard</td>
                <td>UK</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/uk/wizard</a>
                </td>
                <td>
                    <a></a>
                    <a>https://config.emea.tdk-lambda.com/nv/</a>
                </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>parametric</td>
                <td>UK</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/uk/parametric</a>
                </td>
                <td>
                    <a></a>
                    <a>https://config.emea.tdk-lambda.com/nv/</a>
                </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>quickfinder</td>
                <td>UK</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/uk/quickfinder</a>
                </td>
                <td>
                    <a></a>
                    <a>https://config.emea.tdk-lambda.com/nv/</a>
                </td>
            </tr>
            <tr>
                <td>quickfinder</td>
                <td>EMEA</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/quickfinder</a>
                </td>
                <td>
                    <a></a>
                    <a>https://config.emea.tdk-lambda.com/nv/</a>
                </td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>rapidselector</td>
                <td>UK</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/uk/rapidselector</a>
                </td>
                <td>
                    <a></a>
                    <a>https://config.emea.tdk-lambda.com/nv/</a>
                </td>
            </tr>
            <tr>
                <td>rapidselector</td>
                <td>Netherlands</td>
                <td>
                    <a></a>
                    <a>https://www.emea.lambda.tdk.com/nl/rapidselector</a>
                </td>
                <td>
                    <a></a>
                    <a>https://config.emea.tdk-lambda.com/nv/</a>
                </td>
            </tr>
            </tbody>
        </table>

        <p>The following are the terms which are blacklisted for use in shortcuts:</p>

        <ul className="blacklist-list">
            {blacklist.map(bl => <li key={bl}>{bl}</li>)}
        </ul>

        </section>
    )
}

export default Reserved

export const removeKnownHosts = (url: string) => {
    if(url.indexOf('emea.lambda.tdk.com') > -1) {
        return url.replace(/^(https?\:\/\/)?www\.emea\.lambda\.tdk\.com\/.+?\//, '')
    }

    if(url.startsWith('/')){
        return url.substring(1)
    }

    return url
}
import * as React from 'react';

import './loading.css';

class Loading extends React.Component<any, any> {
    render() {
        return <div className="loading-container">
            <div className="loading"></div>
        </div>
    }
}

export default Loading;
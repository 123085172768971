import { doesUserOwnRewriteEntities } from 'common/rewriteEntity'
import { ShortcutGroup } from 'common/shortcutGroup.interface'
import { User } from 'common/user.interface'
import { groupBy, map, sortBy } from 'lodash'
import { Shortcut } from 'types/shortcut.interface'

/**
 *
 * @param shortcuts
 * @param expandedGroups
 * @param currentUser
 */
const mapGroups = (
    shortcuts: Shortcut[],
    expandedGroups: string[],
    currentUser: User
): ShortcutGroup[] => {
    // Trim & lowercase group names. This allows us to match the expanded group names with the groups themselves
    const normalisedExpandedGroups = expandedGroups.map(groupName =>
        groupName.toLowerCase().trim()
    )

    //
    const groups = groupBy(shortcuts, sc => sc.from_path.toLowerCase().trim())

    //
    const mapped = map(
        groups,
        (unsortedRules: Shortcut[], key, idx): ShortcutGroup => {
            // Ensure consistent order by sorting by country
            const sortedByCountry = sortBy(
                unsortedRules,
                (rule: Shortcut) => rule.rewrite_entity_id
            )

            return {
                key,
                shortcuts: sortedByCountry,
                isExpanded: normalisedExpandedGroups.indexOf(key) > -1,
                canDelete: doesUserOwnRewriteEntities(
                    currentUser.managedRewriteEntities,
                    unsortedRules.map(sc => sc.rewrite_entity_id)
                ),
            }
        }
    )

    return sortBy(mapped, group => group.key.toLowerCase())
}

export { mapGroups }

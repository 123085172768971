const blacklist = [
    'public',
    'admin',
    'products',
    'technical-centre',
    'technical-data',
    'distributors',
    'news',
    'about',
    'contact',
    'legal',
    'faq',
    'kb',
    'support',
    'landing',
    'rss',
    'user',
    'code',
    'controls',
    'assets',
    'bin',
]

const offsite = [
    'twitter',
    'facebook',
    'youtube',
    'linkedin',
    'googleplus',
    'blog',
    'zplus_blog',
    'wizard',
    'parametric',
    'quickfinder',
    'rapidselector'
]

export {
    blacklist,
    offsite,
}
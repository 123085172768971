import flagMap from 'common/flagMap'
import RewriteRuleContext from 'config/rewriteRuleContext'
import UserContext from 'config/userContext'
import { find } from 'lodash'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import { useContext } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import './header.scss'

const Menu = () => {
    const history = useHistory()
    const user = useContext(UserContext)
    const { rewriteEntities } = useContext(RewriteRuleContext)

    if (!user?.email || rewriteEntities.length === 0) {
        return null
    }

    const flags = user.managedRewriteEntities.map(entityId => {
        const entity = find(rewriteEntities, { id: entityId })
        return entity ? (
            <img
                key={`flag-${entityId}`}
                alt={entity.code}
                className="owned-flag"
                src={flagMap[entity.code]}
            />
        ) : null
    })

    flags.unshift(<p key="flag-email">{user.email}</p>)

    return (
        <div className="menu">
            <div className="pure-menu">
                <a href="/" className="pure-menu-heading pure-menu-link">
                    <img src={require('../img/logo.png').default} alt="TDK-Lambda" />
                </a>
                <ul className="pure-menu-list">
                    <li className="pure-menu-item">
                        <Link to="/shortcuts" className="pure-menu-link">
                            Shortcuts
                        </Link>
                    </li>
                    <li className="pure-menu-item">
                        <Link to="/shortcuts/reserved" className="pure-menu-link">
                            Reserved Shortcuts
                        </Link>
                    </li>
                </ul>

                <ul className="pure-menu-list">
                    <li>
                        <Tooltip overlay={flags}>
                            <button
                                onClick={() => {
                                    window.localStorage.clear()
                                    history.replace('/login')
                                }}
                                className="pure-menu-link link-button">
                                Logout
                            </button>
                        </Tooltip>
                    </li>
                </ul>
            </div>
        </div>
    )

}

export default Menu